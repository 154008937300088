import { useContext, useEffect, useState } from "react";
import useToken from '../../useToken.js';
import { useParams } from 'react-router-dom'
import { Button} from 'react-bootstrap';

function formatDate(string){
  var options = { month: 'short', day: 'numeric', hour:'numeric', minute:'numeric'};
  return new Date(string).toLocaleDateString([],options);
}

function handleChange(e) {

    // Declare variables
    var table, tr, i, j, colValue, position, th;
    const targ = e.target
    console.log("Initial Filter is "+ targ.value);
    const all_inputs = document.getElementsByTagName('input');
    filterFunc(targ,true)

    for (i = 0; i < all_inputs.length; i++) {
    console.log("Checking existing filter on: " + all_inputs[i].id)
    filterFunc(all_inputs[i], false)
    }

}

function filterFunc(targ, initial) {
 var input, filter, table, tr, td, i, txtValue, colValue, position, th;
    position = 0
    const inputColVal  = targ.id.replace(" Search", "");
    table = document.getElementById("alert_table");

    tr = table.getElementsByTagName("tr");

    th = tr[0].getElementsByTagName("th")
    for (i = 0; i < th.length; i++) {
    colValue = th[i].textContent || th[i].innerText;

    if (inputColVal === colValue) {position = i}
    }
    console.log("Col Position : " + position)
    input = targ.value
    console.log("Filtering for " + input)

    if (input != null)
    { filter = input.toUpperCase();
    table = document.getElementById("alert_table");
    tr = table.getElementsByTagName("tr");
    th = tr[0].getElementsByTagName("th")

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[position];
    if (td) {
      txtValue = td.textContent || td.innerText;

    if (initial || tr[i].style.display != "none")

    {if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";

          } else {
            tr[i].style.display = "none";
          }}


    }
    }}


    }


export function OpsAlerts() {
  let { filter } = useParams();
  // Backend URL
  let hostname = window.location.hostname;
  let backendURL = ""
  if(hostname === "localhost"){
    backendURL = "https://localhost:8080"
  }
  else{
    backendURL = "https://toolbox-t6qfqcqcha-lz.a.run.app"
  }
  const { token, setToken, userObject } = useToken();
  const [data, setData] = useState(() => [])
  const params = useParams();

  // Draft Ticket
  async function makedraft(ticket, user) {
    try {
      const response = await fetch(backendURL + '/api/draft_ticket/' + ticket + "/" + user, {
        method: "POST",
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      });
  
      const responseData = await response.json();
      console.info(responseData);
  
      // Check if the response contains a valid result (URL) and open it in a new tab
      if (responseData && responseData.data) {
        window.open(responseData.data, '_blank'); // Open URL in a new tab
      } else if (responseData.error) {
        console.error('Error:', responseData.error);
      }
  
      return responseData;
    } catch (error) {
      console.error('Error creating draft:', error);
    }
  };

  // Ack Ticket
  function ackticket(ticket,user) {
  
    const response = fetch(backendURL + '/api/ack_alert/'+ ticket,{
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    });
  
    return response
   
    };
  // Close Ticket
  function closeticket(ticket,user) {

  const response = fetch(backendURL + '/api/close_alert/'+ ticket,{
    method: "POST",
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });

  return response

  };
  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + "/api/alerts/" + filter, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
        // console.log("CustomersIndex() :: loadData() :: resultJson=" + JSON.stringify(resultJson))
        setData(resultJson.map(fetch_object => {

          return fetch_object
      }))
    }
  };
  




  const DraftButton = props => {
    const { item, index } = props;
    const [newtext, setnewtext] = useState('');
    const [isDisabled, setDisabled] = useState(false);
    const draftcreated = () => {
      setnewtext("Created");
    };
    
    
    return (
      <div>
        <Button onClick={  () => 

          makedraft(item.alias,userObject.email) >
          console.log(item)>
          draftcreated()>
          setDisabled(true)

        }
        disabled={isDisabled}
        >
            
          Draft {newtext}
        </Button>
        <br />
        <br />
      </div>
    )
  }

  const AckButton = props => {
    const { item, index } = props;
    const [acktext, setnewtext] = useState('Ack');
    const [isDisabled, setDisabled] = useState(false);
    const alertacked = () => {
      setnewtext("Acked");
    };
    
    
    return (
      <div>
        <Button onClick={  () => 

          // ackticket(item.alias,userObject.email) >
          alertacked()>
          ackticket(item.alias,userObject.email)>
          setDisabled(true)>  
          setTimeout(window.location.reload.bind(window.location), 2000)
            
          
        }
        >           
        {acktext}
        </Button>
        <br />
        <br />
      </div>
    )
  }

  const CloseButton = props => {
    const { item, index } = props;
    const [closetext, setnewtext] = useState('Close');
    const [isDisabled, setDisabled] = useState(false);
    const alertclosed = () => {
      setnewtext("Closed");
    };
    
    
    return (
      <div>
        <Button onClick={  () => 
          closeticket(item.alias,userObject.email)>
          alertclosed()>
          setDisabled(true)>
          setTimeout(window.location.reload.bind(window.location), 15000)

        }
         disabled={isDisabled}

        >           
          {closetext}
        </Button>
        <br />
        <br />
      </div>
    )
  }
  // Load data
  useEffect(() => {
    loadData()
  }, []);




    return (

      <div>
  


        <div className="main_box">

          {/* Actions */}
          
          {/* Table */}
          <table id="alert_table" className="table table-striped table-dark">
           <thead>
            <tr>
             <th>
              <span>Time</span>
             </th>
             <th>
              <span>ID</span>
             </th>
             <th>
              <span>Alert</span>
             </th>
             <th>
              <span>Owner</span>
             </th>
             <th>
              <span>Status</span>
             </th>
             <th>
              <span></span>
             </th>
             <th>
              <span></span>
             </th>
            </tr>


            <tr>
           <th className="th_sub_headline">
            <span><input type="input" className="btn_default" id="Time Search" onChange={handleChange}/></span>
           </th>
           <th className="th_sub_headline">
            <span><input type="input" className="btn_default" id="ID Search" onChange={handleChange}/></span>
           </th>
           <th className="th_sub_headline">
            <span><input type="input" className="btn_default" id="Alert Search" onChange={handleChange}/></span>
           </th>
           <th className="th_sub_headline">
            <span><input type="input" className="btn_default" id="Owner Search" onChange={handleChange}/></span>
           </th>
           <th className="th_sub_headline">
            <span><input type="input" className="btn_default" id="Status Search" onChange={handleChange}/></span>
           </th>
           <th className="th_sub_headline">
           </th>
          </tr>


           </thead>
          <tbody>
          {data.map((item, index) => (
              <tr key={index}>

               <td>
                  <span>{formatDate(item.createdAt)}</span>
               </td>
               <td>
                  <span>{item.tinyId}</span>
               </td>
               <td>
                  <span>
                    <a href={"https://advcyber.app.eu.opsgenie.com/alert/detail/" + item.alias + "/details"} target="_blank" >{item.message}</a>
                  </span>
               </td>
               <td>
                  {item.owner != 0 ?
                    (<span>{item.owner.split("@")[0][0].toUpperCase()}{item.owner.split("@")[0].slice(1)}</span>)
                    : 
                    (
                      <AckButton item={item} key={index} index={index} />
                    )
                  }
               </td>
               <td>
                  <span>
                    {item.status[0].toUpperCase()}{item.status.slice(1)}
                  </span>
               </td>
               <td>
                  <DraftButton item={item} key={index} index={index} />
               </td>
               <td>
               {
                  (() => {
                      if (item.status !== "closed" && item.owner === userObject.email)
                          return <span>
                          <CloseButton item={item} key={index} index={index} />
                          </span>
                  })()
                  }
               </td>
              </tr>
          ))}
          </tbody>
          </table>
        </div>
      </div>
      );
  }
    
  export default OpsAlerts;
  ;



